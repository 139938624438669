<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ PageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitData"
          >
            <v-card-title> </v-card-title>
            <v-card-text>
              <v-row wrap>
                <v-col align="start" cols="12" md="12">
                  <div class="notes-content">
                    <p>
                      <span class="text-danger">*</span> indicates required
                      field
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    class="font-size-h6 mr-3 white--text"
                    @click.prevent="refreshPageData"
                    color="#a4c639"
                  >
                    Refresh
                  </v-btn>
                  <br />
                </v-col>
              </v-row>
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6><span class="text-danger">*</span> Portfolio</h6>
                    </label>
                    <v-autocomplete
                      :reduce="(option) => option.value"
                      :loading="PortfolioIdOptionsLoading"
                      :items="PortfolioIdOptions"
                      :rules="PortfolioIdRules"
                      v-model="PortfolioId"
                      required
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6><span class="text-danger">*</span> Form Name</h6>
                    </label>
                    <v-text-field
                      v-model="FormName"
                      :rules="FormNameRules"
                      placeholder="Form name"
                      hint="Enter form name"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6><span class="text-danger">*</span> Upload File</h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="*"
                      placeholder="Upload File"
                      prepend-icon="mdi-file"
                      append-outer-icon="mdi-delete"
                      v-model="UploadedFile1"
                      @click:append-outer="UploadedFile1 = {}"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="submitForm"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="font-size-h6 px-10 mr-3 white--text"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,

      LoadingFlag: false,
      SubmitFlag: false,

      PortfolioIdRules: [(v) => !!v || "App Type is required"],
      PortfolioId: "",
      PortfolioIdOptions: [],
      PortfolioIdOptionsLoading: false,

      FormName: "",
      FormNameRules: [],

      Description: "",
      DescriptionRules: [],

      Content: "",
      ContentRules: [],

      Settings: {},
      UploadedFile1: {},
      SettingsImage: {},

      image: {
        src: "",
        type: null,
      },

      current_photo: "",

      rowData: {
        PopupId: 1,
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",

      config: {
        pluginsEnabled: ["codeView", "table"],
        attribution: false,
        events: {
          "froalaEditor.initialized": function () {
            console.log("initialized");
          },
        },
      },
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        var ZoneCode = this.$session.get("ZoneId");
        ZoneCode = ZoneCode == (null || undefined) ? "" : ZoneCode;
        console.log("ZoneCode=" + ZoneCode);
        this.ZoneCode = ZoneCode;

        this.pageData();
      }
    },
    PortfolioIdOptions: function () {
      console.log("watch PortfolioIdOptions");
      this.LoadingFlag = false;
      this.PortfolioIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  mounted() {
    var editor = new FroalaEditor("#editor1", {
      attribution: false,
    });
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "downloads",
        Action: "create",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getPortfolioIdOptions();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.rowData);
    },
    getPortfolioIdOptions() {
      console.log("getPortfolioIdOptions called");
      this.PortfolioIdOptionsLoading = true;
      var selectbox1_source = "PortfolioId";
      var selectbox1_destination = "PortfolioIdOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        CategoryMainName: 'forms_type',
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // validate1 = false;

      if (validate1) {
        let upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("Portfolio", this.PortfolioId);
        upload.append("FormName", this.FormName);
        upload.append("UploadedFile1", this.UploadedFile1);

        console.log({ upload });

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-forms/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.progessStart = 1;
        this.SubmitFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.sweetAlert("success", output, true);
              // thisIns.ShowFormFlag = false;
              thisIns.closePrompt();
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        // this.toast("error", message);
        this.sweetAlert("error", output, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-event-image {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>